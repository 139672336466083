<template>
  <div></div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  created () {
    const { auth_token, channel_id, email, ewallet, name, seller_type, store_channelid, store_url, favicon_url } = this.$route.query

    if (!auth_token || !channel_id || !email || !ewallet || !name || !seller_type || !store_channelid || !store_url || !favicon_url) {
      this.$router.push({ path: '/auth/login' })
    }

    this.$store.commit('user/SET_STATE', {
      accessToken: auth_token,
      channel_id,
      email,
      wallet: ewallet,
      seller_type,
      store_channelid,
      store_url,
      favicon_url,
    })

    Cookies.set('auth_token', auth_token)
    Cookies.set('channel_id', channel_id)
    Cookies.set('email', email)
    Cookies.set('ewallet', ewallet)
    Cookies.set('name', name)
    Cookies.set('seller_type', seller_type)
    Cookies.set('store_channelid', store_channelid)
    Cookies.set('store_url', store_url)
    Cookies.set('favicon_url', favicon_url)

    this.$store.dispatch('account/GETUSERPROFILE')
      .then(() => {
        this.$router.push({ path: '/dashboard' })
      })
      .catch((err) => {
        console.log(err.response)
        this.$router.push({ path: '/auth/login' })
      })
  },
}
</script>

<style>

</style>
